interface DateRange {
  issueDate: string;
  dueDate: string;
}

export const getRangeDate = (numDays?: number): DateRange => {
  const numberOfDays = numDays || 30;
  const issueDate = new Date();
  let dueDate = new Date();
  dueDate.setDate(dueDate.getDate() + numberOfDays);

  const issueDateYear = issueDate.getFullYear();
  const issueDateMonth = `${issueDate.getMonth() + 1}`.padStart(2, "0");
  const issueDateDay = `${issueDate.getDate()}`.padStart(2, "0");
  const dueDateYear = dueDate.getFullYear();
  const dueDateMonth = `${dueDate.getMonth() + 1}`.padStart(2, "0");
  const dueDateDay = `${dueDate.getDate()}`.padStart(2, "0");

  const formattedIssueDate = `${issueDateYear}${issueDateMonth}${issueDateDay}`;
  const formattedDueDate = `${dueDateYear}${dueDateMonth}${dueDateDay}`;

  return { issueDate: formattedIssueDate, dueDate: formattedDueDate };
};
