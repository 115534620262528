import { parseCsv } from ".";
import { FUEL_GOOGLE_SHEET_ID } from "./constants";

export interface FuelRow {
  Week: number;
  Standard: number;
  Express: number;
  Expedited: number;
}

const parsePercentage = (v: string) => {
  if (v === "" || v === null) return 0;
  const parsed = parseFloat(v.replace(",", ".").replace("%", ""));
  if (isNaN(parsed)) return 0;
  return parsed / 100;
};

export async function fetchFuelData() {
  const response = await fetch(
    "https://docs.google.com/spreadsheets/d/" +
      FUEL_GOOGLE_SHEET_ID +
      "/export?format=csv"
  ).then((res) => res.text());

  // Parse the string and remove the first row (header)
  const data = await parseCsv<[number, string, string, string, string]>(
    response
  ).then((res) => res.slice(1));

  const weeks = new Map<number, Omit<FuelRow, "Week">>();

  data.forEach((v) => {
    weeks.set(v[0], {
      Standard: parsePercentage(v[2]),
      Express: parsePercentage(v[3]),
      Expedited: parsePercentage(v[4]),
    });
  });

  return weeks;
}

export function getFuelPercentage(
  fuelData: Map<number, Omit<FuelRow, "Week">>,
  serviceType: string,
  week: number
) {
  if (
    ["dom. standard", "tb standard", "ww standard"].includes(
      serviceType.toLowerCase()
    )
  )
    return fuelData.get(week)?.Standard ?? 0;
  else return fuelData.get(week)?.Express ?? 0;
}
