import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TextAreaInput from "./TextAreaInput";
import React, { useState, useEffect } from "react";
import { Files } from "../interface/File.interface";

interface TabsComponentProps {
  handleDelete: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, fileName: string) => void;
}
const TabsComponent: React.FC<Files & TabsComponentProps> = ({ files, handleDelete }) => {
  const [value, setValue] = useState("0");

  useEffect(() => {
    setValue("0");
  }, [files]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {files?.map((file, index) => (
              <Tab
                key={index}
                value={`${index}`}
                style={{ position: "relative" }}
                label={
                  <span>
                    {file.fileName}
                    <span
                      onClick={(event) => handleDelete(event, `${file.fileName}`)}
                      className="CloseTabButton"
                    >
                      x
                    </span>
                  </span>
                }
              />
            ))}
            {/* {files?.map((file, index) => (
              <div key={index} onClick={() => handleDelete(`${file.fileName}`)}>
                X
              </div>
            ))} */}
          </TabList>
        </Box>
        {files?.map((file, index) => (
          <TabPanel key={index} value={`${index}`}>
            <TextAreaInput file={file.data}></TextAreaInput>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default TabsComponent;
