import { utils } from "xlsx-js-style";
import {
  HEADER_BASE_STYLE,
  HEADER_SECONDARY_STYLE,
  Row,
  SUM_STYLE,
  SUM_TITLE_STYLE,
  TRACKING_NUMBER_STYLE,
} from "./spreadsheet";
import { Moment } from "moment";
import { DATE_FORMAT, DATE_FORMAT_2, DATE_REGEX_2 } from "./constants";
import moment from "moment";

export const generateSumRow = (dataLength: number, currencyFormat: string) => [
  {
    t: "s",
    v: "Suma",
    s: SUM_TITLE_STYLE,
  },
  {
    t: "n",
    f: `SUM(R2:R${dataLength + 1})`,
    z: `0.00 ${currencyFormat}`,
    s: SUM_STYLE,
  },
  {
    t: "n",
    f: `SUM(S2:S${dataLength + 1})`,
    z: `0.00 ${currencyFormat}`,
    s: SUM_STYLE,
  },
  {
    t: "n",
    f: `SUM(T2:T${dataLength + 1})`,
    z: `0.00 ${currencyFormat}`,
    s: SUM_STYLE,
  },
];

export const createSheet = (data: Row[], header: string[]) => {
  // Create the sheet from the data
  const styledData = data.map((row) => ({
    ...row,
    trackingNumber: {
      t: "s",
      v: row.trackingNumber,
      s: TRACKING_NUMBER_STYLE,
    },
  }));
  const sheet = utils.json_to_sheet(styledData);

  const styledHeader = header.map((value, index) => ({
    t: "s",
    v: value,
    s: index > 20 ? HEADER_SECONDARY_STYLE : HEADER_BASE_STYLE,
  }));

  utils.sheet_add_aoa(sheet, [styledHeader], {
    origin: "A1",
  });

  return sheet;
};

export const round = (value: number, places: number = 2) =>
  Math.round(value * Math.pow(10, places)) / Math.pow(10, places);

export const extractDate = (date: string): Moment => {
  if (date.match(DATE_REGEX_2)) {
    return moment(date, DATE_FORMAT_2);
  } else {
    return moment(date, DATE_FORMAT);
  }
};

export const parseNumber = (value: string) => {
  if (typeof value === "string") {
    return parseFloat(value.replace(",", "."));
  } else if (typeof value === "number") {
    return value;
  } else {
    return NaN;
  }
};
