import { Error } from "../interface/Error.interface";
const ErrorMessage: React.FC<Error> = ({ message }) => {
  return (
    <>
      {message ? (
        <div>
          <p>{message}</p>
        </div>
      ) : null}
    </>
  );
};
export default ErrorMessage;
