import { ValueOf } from "./types";

// Sheets needs to be public
export const FUEL_GOOGLE_SHEET_ID =
  "1Ugt77eSUszwoJEVDuWwmAanilxVY_N1HQaHfQz4f9Vw";
export const MAPPING_GOOGLE_SHEET_ID =
  "1Iia_gC9gcz4TOufSsCDaDImazUgvHtAcIg-9CETWuSQ";

export const VAT_RATE = 0.21;

export const CURRENCY = {
  CZK: { rate: 1, format: "Kč" },
  EUR: { rate: 23.5, format: "€" },
} as const;

export type CurrencyValue = ValueOf<typeof CURRENCY>;
export type Currency = typeof CURRENCY;

// These indexes correspond to the column number in the csv data file
export const DIRECTION_INDEX = 6; // import or export
export const IMPORT_TRACKING_NUMBER_INDEX = 20;
export const EXPORT_TRACKING_NUMBER_INDEX = 13;
export const INVOICE_UPS_INDEX = 5;
export const ACCOUNT_INDEX = 2;
export const SHIPMENT_DATE_INDEX = 11;
export const REFERENCE_ONE_INDEX = 15;
export const REFERENCE_TWO_INDEX = 16;
export const DESTINATION_INDEX = 81;
export const SHIPPER_INDEX = 67;
export const ORIGIN_INDEX = 73;
export const BILLED_WEIGHT_INDEX = 28;
export const PACKAGE_COUNT_INDEX = 18;
export const SERVICE_TYPE_INDEX = 45;
export const CONTAINER_TYPE_INDEX = 30;
export const RECEIVER_COMPANY_NAME = 74;
export const RECEIVER_ADDRESS = 75;
export const RECEIVER_CITY = 76;

// Names for this columns in the csv data file are unknown
export const UNKNOWN_INDEX_1 = 43;
export const UNKNOWN_INDEX_2 = 34;
export const UNKNOWN_INDEX_3 = 35;
export const UNKNOWN_INDEX_4 = 44;
export const UNKNOWN_INDEX_5 = 52;

// Date format regexes
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_REGEX_2 = /^\d{2}\.\d{2}\.\d{4}$/;
export const DATE_FORMAT_2 = "DD.MM.YYYY";
