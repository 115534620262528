import { Files } from "../interface/File.interface";
import jszip from "jszip";
const DownloadButton: React.FC<Files> = ({ files }) => {
  const onClickDownload = async () => {
    const zip = jszip();
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].fileName, files[i].data);
    }
    const zipFile = await zip.generateAsync({ type: "blob" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(zipFile);
    a.download = "xmlFiles.zip";
    a.click();
  };

  return (
    <div>
      {files.length > 0 ? (
        <div className="DownloadFileButton" onClick={onClickDownload}>
          Download all files
        </div>
      ) : null}
    </div>
  );
};

export default DownloadButton;
