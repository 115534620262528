import { getFormattedDate } from "./GetFormattedDate";
interface transactionDetails {
  trackingNumber?: string;
  reference1?: string;
  reference2?: string;
  transactionDateCCYYMMDD?: string;
  billedWeight?: string;
  unitOfMeasurement?: string;
  senderAttentionName?: string;
  senderCountryCode?: string;
  consigneeCountryCode?: string;
  descriptionOfCharges?: string;
  netAmount?: string;
  currencyCode?: string;
}

export const getTransactionDetails = (headings: string[], details: string[]) => {
  let transactionDetails: transactionDetails = {};
  //matches only dots that have a digit before and after them
  const dotsRegex = /(?<=\d)\.(?=\d)/g;
  transactionDetails.currencyCode = "CZK";
  for (let j = 0; j < headings.length; j++) {
    switch (headings[j]) {
      case "Tracking number":
        transactionDetails.trackingNumber = details[j];
        break;
      case "Shipment date":
        transactionDetails.transactionDateCCYYMMDD = getFormattedDate(details[j]);
        break;
      case "Reference 1":
        if (details[j] !== "") {
          transactionDetails.reference1 = details[j].replace(/"/g, "").replace(dotsRegex, ",");
        }
        break;
      case "Reference 2":
        if (details[j] !== "") {
          transactionDetails.reference2 = details[j].replace(/"/g, "").replace(dotsRegex, ",");
        }
        break;
      case "Destination":
        transactionDetails.consigneeCountryCode = details[j];
        break;
      case "Billed Weight":
        transactionDetails.billedWeight = details[j];
        transactionDetails.unitOfMeasurement = "K";
        break;
      case "Service Type":
        transactionDetails.descriptionOfCharges = details[j];
        break;
      case "Net Total":
        transactionDetails.netAmount = details[j];
        break;
      case "Origin":
        transactionDetails.senderCountryCode = details[j];
        break;
      case "Shipper":
        transactionDetails.senderAttentionName = details[j].replace(/"/g, "");
        break;
      default:
        break;
    }
  }

  return transactionDetails;
};
