import { useRef } from "react";
import { InputDataButtonProps } from "../interface/Button.interface";

const InputDataButton: React.FC<InputDataButtonProps> = ({ handleFileChange }) => {
  //Create a reference to the hidden file input element
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClickInput = () => {
    hiddenFileInput.current?.click();
  };
  return (
    <form id="input-data-form">
      <div className="InputFileButton" onClick={handleClickInput}>
        Upload new files
      </div>
      <input
        type="file"
        multiple
        accept=".xlsx, .xls, .csv"
        ref={hiddenFileInput}
        className="InputButton"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </form>
  );
};

export default InputDataButton;
