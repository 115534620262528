import { MappingResult } from "./surcharges";

interface RowBase {
  trackingNumber: string;
  account: string;
  shipmentDate: Date;
  invoicePoolside: string;
  invoiceUps: string;
  reference1: string;
  reference2: string;
  destination: string;
  shipper: string;
  origin: string;
  direction: string;
  billedWeight: number;
  package: number;
  serviceType: string;
  netFreight: number;
  surcharges: number;
  netTotal: number;
  vat: number;
  grandTotal: number;
  week: number;
}

export type Row = {
  [key: keyof RowBase | string]: RowBase[keyof RowBase] | MappingResult;
};

export const HEADER = [
  "Tracking Number",
  "Account",
  "Shipment Date",
  "Invoice Poolside",
  "Invoice UPS",
  "Reference 1",
  "Reference 2",
  "Destination",
  "Shipper",
  "Origin",
  "Direction",
  "Billed Weight",
  "# Package",
  "Service Type",
  "Type of Packaging",
  "Net Freight",
  "Surcharges",
  "Net Total",
  "VAT",
  "Grand Total",
  "Week",
];

export const RECEIVER_INFORMATION_HEADER = [
  "Receiver Company Name",
  "Receiver Address",
  "Receiver City",
];

// Styling

export const HEADER_BASE_STYLE = {
  fill: { fgColor: { rgb: "2C1608" } },
  font: { color: { rgb: "F0BB42" }, bold: true, sz: 12 },
};

export const HEADER_SECONDARY_STYLE = {
  fill: { fgColor: { rgb: "FDF3D0" } },
  font: { bold: true, sz: 12 },
};

export const TRACKING_NUMBER_STYLE = {
  fill: { fgColor: { rgb: "FDF3D0" } },
};

export const SUM_TITLE_STYLE = {
  fill: { fgColor: { rgb: "F0BB42" } },
  font: { bold: true, sz: 12 },
  border: { bottom: { style: "mediumDashed" } },
};

export const SUM_STYLE = {
  fill: { fgColor: { rgb: "E0EAF6" } },
  font: { sz: 12 },
  border: { bottom: { style: "mediumDashed" } },
};
