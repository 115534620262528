import { useState } from "react";
import { processData } from "../utils/csv";
import { WorkBook, writeFile } from "xlsx-js-style";
import { CURRENCY } from "../utils/csv/constants";
import { SubmitHandler, useForm } from "react-hook-form";

import "../styles/ContentContainer.css";
import "../styles/CsvPage.css";

type Currency = keyof typeof CURRENCY;
const CURRENCIES = Object.keys(CURRENCY) as Currency[];

interface FormValues {
  currency: Currency;
  files: FileList;
  ratecard: FileList;
  includeReceiverInfo: boolean;
}

export default function CsvPage() {
  const { register, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      currency: CURRENCIES[0],
    },
  });

  const [workbook, setWorkbook] = useState<WorkBook | null>(null);
  const [checkWorkbook, setCheckWorkbook] = useState<WorkBook | null>(null);

  const process: SubmitHandler<FormValues> = async ({
    currency,
    files,
    ratecard,
    includeReceiverInfo,
  }) => {
    const currencyRate = CURRENCY[currency];

    const { workbook, check } = await processData(
      files,
      ratecard.item(0)!,
      includeReceiverInfo,
      currencyRate
    );

    setWorkbook(workbook);
    setCheckWorkbook(check);
  };

  return (
    <div className="container">
      <form className="file-form" onSubmit={handleSubmit(process)}>
        <div className="file-form-group">
          <label htmlFor="currencyRate">Currency</label>
          <select id="currencyRate" {...register("currency")}>
            {Object.keys(CURRENCY).map((m, i) => (
              <option value={m}>{m}</option>
            ))}
          </select>
        </div>
        <div className="file-form-group">
          <label htmlFor="ratecard-input">Ratecard</label>
          <input
            id="ratecard-input"
            type="file"
            accept=".xlsx, .xls"
            {...register("ratecard", { required: "Please upload a ratecard" })}
          />
        </div>

        <div className="file-form-group">
          <label htmlFor="csv-input">CSV Data</label>
          <input
            id="csv-input"
            type="file"
            multiple
            accept=".xlsx, .xls, .csv"
            {...register("files", {
              required: "Please upload at least one file",
            })}
          />
        </div>

        <div>
          <label>
            <input
              type="checkbox"
              {...register("includeReceiverInfo")}
              defaultChecked
            />
            Include receiver information
          </label>
        </div>

        <button type="submit" disabled={!formState.isValid}>
          Process
        </button>
      </form>

      <hr />

      {formState.isSubmitting && <p>Processing...</p>}

      {formState.isSubmitSuccessful && workbook && checkWorkbook && (
        <>
          <p>Done!</p>
          <button
            onClick={() => {
              writeFile(workbook, "output.xlsx");
            }}
          >
            Download
          </button>
          <button
            onClick={() => {
              writeFile(checkWorkbook, "check.xlsx");
            }}
          >
            Download check file
          </button>
        </>
      )}
    </div>
  );
}
