import Logo from "../assets/logo.webp";
import "../styles/Header.css";
import { Link, useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="Header">
      <img src={Logo} alt="Poolside logo" onClick={handleClick}></img>
      <div className="navigation">
        <Link to="/">XML</Link>
        <Link to="/csv">CSV</Link>
      </div>
    </div>
  );
};

export default Header;
