import { utils, read, Sheet2CSVOpts, ParsingOptions } from "xlsx-js-style";
import { isDataUpsValid } from "./IsDataUpsValid";
interface File {
  fileName?: string;
  data: string;
  error?: string;
}
export const xslxToCsv = (data: string): File => {
  const sheetToCsvOpts: Sheet2CSVOpts = {
    blankrows: false,
    FS: ",",
    RS: "\n",
  };
  const readOpts: ParsingOptions = {
    raw: true,
    type: "binary",
  };

  let file: File = { data: "" };
  // matches only commas that are not inside double quotes
  const regexComma = /(?=(?:[^"]*"[^"]*")*[^"]*$),/g;
  let grandTotal = 0.0;
  let netTotal = 0.0;
  let vat = 0.0;
  let dataToSave = "";
  let currency = "";
  const workbook = read(data, readOpts);
  workbook.SheetNames.forEach((sheet, index) => {
    //read each sheet as csv
    const sheetToCsv = utils.sheet_to_csv(
      workbook.Sheets[sheet],
      sheetToCsvOpts
    );
    const splitSheet = sheetToCsv.replace(/(?<=")\r\n/g, "").split("\n");
    const lastRow = splitSheet[splitSheet.length - 1].split(regexComma);
    const headings = splitSheet[0].replace(/"/g, "");
    const isValid = isDataUpsValid(headings);
    if (!isValid) {
      file.error = `Invalid UPS EBR Billing XLSX file.`;
      return file;
    }
    const details = splitSheet.slice(1, splitSheet.length - 1);
    const [trimmedNetTotal, trimmedCurrency] = lastRow[16]
      .replace(/[",]/g, "")
      .trim()
      .split(" ");
    const trimmedVat = lastRow[17]
      .replace(/[",]/g, "")
      .replace(/-/, "0")
      .trim()
      .split(" ")[0];
    netTotal += parseFloat(trimmedNetTotal);
    vat += parseFloat(trimmedVat);
    if (index > 0) {
      dataToSave += `${details.join("\n")}\n`;
    } else {
      dataToSave += `${headings}\n${details.join("\n")}\n`;
    }
    currency = trimmedCurrency;
  });
  grandTotal = netTotal + vat;
  dataToSave += `${netTotal} ${currency},${vat} ${currency},${grandTotal} ${currency}`;
  file.data = dataToSave;
  return file;
};
