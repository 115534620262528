import { ChangeEvent, useState } from "react";
import "../styles/Button.css";
import "../styles/ContentContainer.css";
import "../styles/TextArea.css";
import TabsComponent from "../components/TabsComponent";
import InputDataButton from "../components/InputDataButton";
import DownloadButton from "../components/DownloadButton";
import ErrorMessage from "../components/ErrorMessage";
import { csvToXml } from "../utils/xml/CsvToXml";
import { xslxToCsv } from "../utils/xml/XlsxToCsv";
import { File } from "../interface/File.interface";
import { isDataUpsValid } from "../utils/xml/IsDataUpsValid";

export default function XmlPage() {
  const [xmlFiles, setXmlFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files: inputFiles } = e.target;
    setXmlFiles([]);
    setErrorMessage("");
    if (!inputFiles) {
      return;
    }
    const filesArray = Array.from(inputFiles);
    filesArray.forEach((file) => {
      if (file.type === "text/csv") {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (event) => {
          const data = event.target?.result as string;
          const fileToSave = { data, fileName: file.name };
          const isValid = isDataUpsValid(fileToSave.data);
          if (isValid) {
            const { fileName, data: xmlData } = csvToXml(fileToSave);
            setXmlFiles((prevData) => [
              ...prevData,
              { data: xmlData, fileName },
            ]);
          } else {
            setErrorMessage(`Invalid UPS EBR Billing csv file.`);
          }
        };
      } else if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => {
          const data = event.target?.result as string;
          const dataToSave = xslxToCsv(data);
          if (dataToSave.error) {
            setErrorMessage(dataToSave.error);
            return;
          } else {
            const fileToSave = { data: dataToSave.data, fileName: file.name };
            const { fileName, data: xmlData } = csvToXml(fileToSave);
            setXmlFiles((prevData) => [
              ...prevData,
              { data: xmlData, fileName },
            ]);
          }
        };
      } else {
        setErrorMessage(`File ${file.name} is not a .xlsx, .xls or .csv file.`);
      }
    });
  };

  const handleDelete = (
    event: React.MouseEvent<HTMLSpanElement>,
    fileName: string
  ) => {
    event.stopPropagation();
    const newFiles = xmlFiles.filter((file) => file.fileName !== fileName);
    setXmlFiles(newFiles);
  };

  return (
    <div className="Container">
      <div className="ContentContainer">
        <div className="Button-container">
          <InputDataButton
            handleFileChange={handleFileChange}
          ></InputDataButton>
          <DownloadButton files={xmlFiles}></DownloadButton>
        </div>
        <ErrorMessage message={errorMessage}></ErrorMessage>
        <TabsComponent
          files={xmlFiles}
          handleDelete={handleDelete}
        ></TabsComponent>
      </div>
    </div>
  );
}
