import {
  UNKNOWN_INDEX_1,
  UNKNOWN_INDEX_2,
  UNKNOWN_INDEX_3,
  UNKNOWN_INDEX_5,
} from "./constants";

export function calculateVAT(invoices: string[][]) {
  const vatFrt = findVATValue(invoices, "SHP", UNKNOWN_INDEX_2);
  const vatAdc = findVATValue(invoices, "ADC");
  const vatAdj = findVATValue(invoices, "ADJ");
  const vatRadj = findVATValue(invoices, "RADJ");
  const vatRtn = findVATValue(invoices, "RTN", UNKNOWN_INDEX_2);

  return vatFrt + vatAdc + vatAdj + vatRadj + vatRtn;
}

export function findVATValue(
  invoices: string[][],
  key: string,
  keyIndex = UNKNOWN_INDEX_3
): number {
  const vatData = invoices.find((invoice) => {
    return invoice[keyIndex] === key && invoice[UNKNOWN_INDEX_1] === "TAX";
  });

  return vatData ? Number(vatData[UNKNOWN_INDEX_5]) : 0;
}
