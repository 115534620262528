import { getRangeDate } from "./GetRangeDate";
import { getTransactionDetails } from "./GetTransactionDetails";
import { File } from "../../interface/File.interface";
import { prettifyXml } from "./PrettifyXml";

export const csvToXml = (file: File): File => {
  let { fileName, data } = file;
  // change file extension to csv
  if (fileName.endsWith(".xlsx")) {
    fileName = fileName.replace(".xlsx", ".xml");
  } else if (fileName.endsWith(".xls")) {
    fileName = fileName.replace(".xls", ".xml");
  } else if (fileName.endsWith(".csv")) {
    fileName = fileName.replace(".csv", ".xml");
  }
  // convert data to xml
  // matches only commas that are not inside double quotes
  const regex = /(?=(?:[^"]*"[^"]*")*[^"]*$),/;
  let xml = "";
  const csvData = data.split("\n");
  const headings = csvData[0].split(regex).slice(0, 19);
  const secondRow = csvData[1].split(regex);
  const lastRow = csvData[csvData.length - 1].split(regex);
  const [totalNetAmmount, currency] = lastRow[0].split(" ");
  const { issueDate, dueDate } = getRangeDate();
  const poolsideInvoiceNumber = secondRow[3];
  const upsInvoiceNumber = secondRow[4];
  const typeCode = secondRow[10];
  const accountNumber = secondRow[1];
  const countryOrTerritoryCode = secondRow[9];
  let currencyCode = "";
  if (currency === "€") {
    currencyCode = "EUR";
  } else if (currency === "$") {
    currencyCode = "USD";
  } else {
    currencyCode = "CZK";
  }

  xml += '<?xml version="1.0" encoding="ISO-8859-1"?>\n';
  xml +=
    '<UPS_EBR_BILL xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.ups.com/XMLSchema/EBR/Billing/v1" xsi:schemaLocation="http://www.ups.com/XMLSchema/EBR/Billing/v1 Billing_XML_Schema.xsd">\n';
  xml +=
    "<Identification>\n<Name>Poolside Logistics s.r.o.</Name>\n<ID>17431417</ID>\n";
  xml += `<PoolsideInvoiceNumber>${poolsideInvoiceNumber}</PoolsideInvoiceNumber>\n`;
  xml += "</Identification>\n<InvoiceDetails>\n<Invoice>\n";
  xml += `<InvoiceNumber>${upsInvoiceNumber}</InvoiceNumber>\n`;
  xml += `<InvoiceDateCCYYMMDD>${issueDate}</InvoiceDateCCYYMMDD>\n`;
  xml += `<InvoiceCurrencyCode>${currencyCode}</InvoiceCurrencyCode>\n`;
  xml += `<InvoiceAmount>${totalNetAmmount}</InvoiceAmount>\n`;
  xml += `<InvoiceDueDateCCYYMMDD>${dueDate}</InvoiceDueDateCCYYMMDD>\n`;
  xml += `<TypeCode>${typeCode}</TypeCode>\n`;
  xml += `<Account>\n`;
  xml += `<AccountNumber>${accountNumber}</AccountNumber>\n`;
  xml += `<CountryorTerritoryCode>${countryOrTerritoryCode}</CountryorTerritoryCode>\n`;
  xml += `</Account>\n`;

  for (let i = 1; i < csvData.length - 1; i++) {
    const details = csvData[i].split(regex);
    const {
      trackingNumber,
      reference1,
      reference2,
      transactionDateCCYYMMDD,
      billedWeight,
      unitOfMeasurement,
      senderAttentionName,
      senderCountryCode,
      consigneeCountryCode,
      descriptionOfCharges,
      netAmount,
      currencyCode,
    } = getTransactionDetails(headings, details);
    xml += "<TransactionDetails>\n";
    // shipment
    xml += "<Shipment>\n";
    xml += `<TransactionDateCCYYMMDD>${transactionDateCCYYMMDD}</TransactionDateCCYYMMDD>\n`;
    xml += `<LeadShipmentNumber>${trackingNumber}</LeadShipmentNumber>\n`;
    xml += `<ShipmentReference>\n`;
    if (reference1) {
      xml += `<Sequence>1</Sequence>\n<Reference>${reference1}</Reference>\n`;
    }
    if (reference2) {
      xml += `<Sequence>2</Sequence>\n<Reference>${reference2}</Reference>\n`;
    }
    xml += `</ShipmentReference>\n`;
    // package
    xml += `<Package>\n`;
    xml += `<TrackingNumber>${trackingNumber}</TrackingNumber>\n`;
    xml += `<PackageWeight>\n`;
    xml += `<BilledWeight>\n`;
    xml += `<Weight>${billedWeight}</Weight>\n`;
    xml += `<UnitOfMeasure>${unitOfMeasurement}</UnitOfMeasure>\n`;
    xml += `</BilledWeight>\n`;
    xml += `</PackageWeight>\n`;
    // charge details
    xml += `<ChargeDetails>\n`;
    xml += `<Charge>\n`;
    xml += `<DescriptionOfCharges>${descriptionOfCharges}</DescriptionOfCharges>\n`;
    xml += `<CurrencyCode>${currencyCode}</CurrencyCode>\n`;
    xml += `<NetAmount>${netAmount}</NetAmount>\n`;
    xml += `</Charge>\n`;
    xml += `</ChargeDetails>\n`;
    xml += `</Package>\n`;
    xml += `</Shipment>\n`;
    // address details
    xml += `<AddressDetails>\n`;
    xml += `<SenderAddress>\n`;
    xml += `<Attention>\n`;
    xml += `<Name>${senderAttentionName}</Name>\n`;
    xml += `</Attention>\n`;
    xml += `<Address>\n`;
    xml += `<CountryorTerritoryCode>${senderCountryCode}</CountryorTerritoryCode>\n`;
    xml += `</Address>\n`;
    xml += `</SenderAddress>\n`;
    xml += `<ConsigneeAddress>\n`;
    xml += `<Address>\n`;
    xml += `<CountryorTerritoryCode>${consigneeCountryCode}</CountryorTerritoryCode>\n`;
    xml += `</Address>\n`;
    xml += `</ConsigneeAddress>\n`;
    xml += `</AddressDetails>\n`;
    xml += "</TransactionDetails>\n";
  }
  xml += "</Invoice>\n</InvoiceDetails>\n</UPS_EBR_BILL>\n";
  const prettifiedXml = prettifyXml(xml);
  return { data: prettifiedXml, fileName };
};
