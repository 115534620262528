interface TextAreaProps {
  file: string;
}

const TextAreaInput: React.FC<TextAreaProps> = ({ file }) => {
  if (!file) return <div></div>;
  return <textarea value={file} readOnly className="TextArea" />;
};

export default TextAreaInput;
