export const isDataUpsValid = (data: string): boolean => {
  // matches only commas that are not inside double quotes
  const regex = /(?=(?:[^"]*"[^"]*")*[^"]*$),/;
  let isValid = true;
  const heading = data.replace(/(?<=")\r\n/g, "").split("\n")[0];
  if (heading.split(regex).length < 18) {
    isValid = false;
    return isValid;
  }
  const headings = heading.split(regex).slice(0, 19);
  for (let i = 0; i < headings.length; i++) {
    if (
      headings[i] !== "Tracking number" &&
      headings[i] !== "Shipment date" &&
      headings[i] !== "Account" &&
      headings[i] !== "Invoice UPS" &&
      headings[i] !== "Invoice Poolside" &&
      headings[i] !== "Reference 1" &&
      headings[i] !== "Reference 2" &&
      headings[i] !== "Destination" &&
      headings[i] !== "Shipper" &&
      headings[i] !== "Origin" &&
      headings[i] !== "Direction" &&
      headings[i] !== "Billed Weight" &&
      headings[i] !== "# Packages" &&
      headings[i] !== "Service Type" &&
      headings[i] !== "Net Freight" &&
      headings[i] !== "Surcharges" &&
      headings[i] !== "Net Total" &&
      headings[i] !== "VAT" &&
      headings[i] !== "Grand Total"
    ) {
      isValid = false;
      return isValid;
    }
  }
  return isValid;
};
